@tailwind base;
@tailwind components;
@tailwind utilities;

.field-style {
  @apply border px-3 py-2 rounded;
}

label {
  display: flex;
  flex-direction: column;
}

#form {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: flex-end;
  gap: 0.5rem;
}

button {
  @apply px-3 py-2 rounded bg-sky-500 text-white;
}

#result {
  margin-top: 1rem;
  max-height: 800px;
}